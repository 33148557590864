html,
body,
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html {
  font-size: 62.5%;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.bold {
  font-weight: bold;
}

.markdown {
  white-space: normal;
}
