.custom-chat {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.custom-channel {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.str-chat__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.str-chat__gallery {
  width: 100%;
  justify-content: initial;
}

.str-chat__list {
  padding: 0 2rem;
  overflow-y: auto;
}

.str-chat__message-attachment {
  border-radius: 0;
}

.str-chat__gallery-placeholder {
  flex: 1;
  background-size: cover;
  position: relative;
  cursor: pointer;
}

.str-chat__gallery-placeholder > p {
  position: relative;
  z-index: 1;
  color: white;
  font-size: 1.6rem;
}

.str-chat__gallery-placeholder::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.str-chat__message-attachment--img {
  max-width: 100%;
  border-radius: 0 !important;
}

.str-chat__attachment-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.str-chat__modal__inner {
  max-width: 90%;
}
